<script>
import { api } from '@/api'
import { mapActions } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    automaticCalculation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timeInputMask: () => '##:##.##',
    timePlaceholder: () => '00:00.00',
    penaltyTimeInputMask: () => '##:##',
    penaltyTimePlaceholder: () => '00:00',
    pointsInputMask: () => '###',
    table: () => {
      return {
        headers: [
          { text: 'Место', value: 'place' },
          { text: 'Команда', value: 'team.teamable.name', sortable: false },
          { text: 'Время прохождения', value: 'time' },
          { text: 'Штрафное время', value: 'penalty_time', sortable: false },
          { text: 'Итоговое время', value: 'total_time' },
          { text: 'Очки', value: 'points_all', sortable: false },
          { text: 'Рейтинг', value: 'rating_all', sortable: false },
          { text: 'Неполный состав', value: 'incomplete_team', sortable: false },
        ],
          list: [],
          meta: {
          total: -1
        },
        options: null
      }
    }
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),

    async onChange(item) {
      if (item.loading) return

      this.$set(item, 'loading', true)

      try {
        await api.festival.relayRace.update(this.$route.params.id, item.id, item)

        this.notify('Данные обновлены!')
      } catch ({ response }) {

        if (response.data?.error) {
          this.error(response.data.error)
        }
      } finally {
        item.loading = false
      }
    }
  }
}
</script>

<template>
  <v-data-table
    :headers="table.headers"
    :items="items"
  >
    <template v-slot:item.time="{ item }">
      <v-text-field
        class="input-width"
        v-model="item.time"
        hide-details
        solo
        dense
        v-mask="timeInputMask"
        :placeholder="timePlaceholder"
        :loading="item.loading"
        @change="onChange(item)"
      />
    </template>
    <template v-slot:item.penalty_time="{ item }">
      <v-text-field
        class="input-width"
        v-model="item.penalty_time"
        hide-details
        solo
        dense
        v-mask="penaltyTimeInputMask"
        :placeholder="penaltyTimePlaceholder"
        :loading="item.loading"
        @change="onChange(item)"
      />
    </template>
    <template v-slot:item.points_all="{ item }">
      <v-text-field
        v-if="automaticCalculation"
        class="input-width"
        v-model="item.points_all"
        hide-details
        solo
        dense
        v-mask="pointsInputMask"
        :loading="item.loading"
        @change="onChange(item)"
      />
      <template v-else>{{ item.points_all }}</template>
    </template>
    <template v-slot:item.incomplete_team="{ item }">
      <v-checkbox
        v-model="item.incomplete_team"
        hide-details
        class="mt-0 pt-0"
        @change="onChange(item)"
      />
    </template>
  </v-data-table>
</template>

<style scoped>
.input-width {
  width: 96px;
}
</style>
