<script>
import FestivalViewTabsComponent from '@/views/Festival/components/festival-view-tabs-component';
import FestivalRelayRaceHeader from "@/views/Festival/components/relay-race/FestivalRelayRaceHeader.vue";
import FestivalRelayRaceTable from "@/views/Festival/components/relay-race/FestivalRelayRaceTable.vue";
import { api } from '@/api'

export default {
  name: 'FestivalTournamentTable',

  components: {
    FestivalViewTabsComponent,
    FestivalRelayRaceHeader,
    FestivalRelayRaceTable,
  },

  data() {
    return {
      items: [],
      isAutomaticCalculation: false,
      searchQuery: '',
    }
  },

  computed: {
    getItems() {
      if (this.searchQuery.length) {
        return this.items.filter((item) => {
          return item.team.teamable.name
            .toLowerCase()
            .startsWith(this.searchQuery.toLowerCase())
        })
      }

      return this.items
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      const { data } = await api.festival.relayRace
        .all(this.$route.params.id)

      this.items = data.list
    }
  }
}
</script>

<template>
  <div>
    <FestivalViewTabsComponent has-relay />

    <div class="pa-3">
      <FestivalRelayRaceHeader
        :automatic-calculation="isAutomaticCalculation"
        @automatic-calculation="isAutomaticCalculation = $event"
        @search="searchQuery = $event"
      />
      <FestivalRelayRaceTable
        :items="getItems"
        :automatic-calculation="isAutomaticCalculation"
      />
    </div>
  </div>
</template>
