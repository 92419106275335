<template>
  <v-container fill-height class="loginOverlay">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm6 md4 elevation-6>
        <v-card>
          <v-card-title class="pb-0">
            <v-img
                contain
                max-height="95"
                max-width="206"
                src="/images/logo-wide.svg"
            ></v-img>
          </v-card-title>
          <v-card-text class="pt-4 pt-0">
            <ValidationObserver
                ref="observer"
                v-slot="{ invalid, handleSubmit }"
            >
              <v-form ref="form">
                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="error"
                >
                  {{ error }}
                </v-alert>

                <ValidationProvider
                    name="e-mail"
                    rules="required|email"
                    ref="email"
                    v-slot="{ errors }"
                >
                  <v-text-field
                      label="Email"
                      :error-messages="errors"
                      v-model="form.email"
                      filled
                      dense
                      immediate
                      :readonly="is_loading"
                      @input="validateField('email')"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider
                    name="пароль"
                    rules="required"
                    ref="password"
                    v-slot="{ errors }"
                >
                  <v-text-field
                      label="Пароль"
                      v-model="form.password"
                      :error-messages="errors"
                      :append-icon="!showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPass = !showPass"
                      :type="!showPass ? 'password' : 'text'"
                      counter
                      filled
                      dense
                      immediate
                      :readonly="is_loading"
                      @input="validateField('password')"
                  >
                  </v-text-field>
                </ValidationProvider>

                <v-layout justify-space-between>
                  <v-btn
                      @click.prevent="handleSubmit(submit)"
                      class="primary"
                      :disabled="invalid || is_loading"
                      :loading="is_loading"
                  >
                    Войти
                  </v-btn>

                  <router-link :to="{name: 'auth.reset-password', query: { email: form.email }}">Забыли пароль?</router-link>
                </v-layout>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate';
import permissions from '@/constants/permisions';

setInteractionMode('lazy');

import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  name: 'Login',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        valid: false,
      },
      showPass: '',
      error: null,
    };
  },
  computed: {
    ...mapState('auth', [
      'is_loading',
    ]),
  },
  methods: {
    ...mapMutations('auth', [
      'set_loading',
    ]),
    ...mapActions('auth', {
      login: 'login'
    }),
    validateField(fieldName) {
      this.$refs[fieldName].validate()
    },
    submit() {
      this.$refs.observer.validate()
        .then(isValid => {
          if (!isValid || this.is_loading) return;
          this.set_loading(true)
          this.error = null;

          this.login(this.form)
            .then((user) => {
              this.$gates.setRoles(user.role.name);
              this.user = user;
              this.$gates.setPermissions(permissions.list(this))
              this.$router.push({name: 'festivals.index'})
            }).catch(() => {
            this.error = 'Неверный логин или пароль'
          })
          .finally(() => this.set_loading(false));
        });
    }
  }
};
</script>
